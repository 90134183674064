import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import { spacing, tokens, media, box } from '@tokens'
import TeaserBeer from '@components/Teasers/TeaserBeer'
import Link from '@elements/Link'

export const beerTeaserGridQueries = () => {
  let queries = ''
  let minWidth = ''
  let beerTeaserRatioObj = tokens.get('beerTeaserRatio')

  for (let row = 2; row <= 4; row++) {
    switch (row) {
      case 2:
        minWidth = '0px'
        break
      case 3:
        minWidth = tokens.get('breakpoints.s')
        break
      case 4:
        minWidth = tokens.get('breakpoints.l')
        break
    }

    Object.keys(beerTeaserRatioObj).forEach((key) => {
      queries += `

      &.row-${row}--${key} {
        @media (min-width: ${minWidth}) {
          padding-bottom: ${beerTeaserRatioObj[key]}
        }
      }
    `
    })
  }

  return css`
    ${queries}
  `
}

export const SeriesBeerList = styled.div`
  ${box({
    spacing: 'bottom',
    multiplier: -0.5,
    type: 'margin',
  })};
  position: relative;
  z-index: ${tokens.z('content')};
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: ${spacing(-2.5)};
  margin-right: ${spacing(-2.5)};
  width: calc(100% + ${spacing(5)});

  .list-item {
    ${box({
      spacing: 'bottom',
      multiplier: 0.5,
      type: 'margin',
    })};
    margin-left: ${spacing(2.5)};
    margin-right: ${spacing(2.5)};
    flex: 0 1 auto;
    width: ${styledMap('teaserVariant', {
      artwork: '100%',
      box: '100%',
      default: css`calc((100% - ${spacing(10)}) / 2)`,
    })};

    ${media.greaterThan('s')`
      width: ${styledMap('teaserVariant', {
        artwork: css`calc((100% - ${spacing(10)}) / 2)`,
        box: '100%',
        default: css`calc((100% - ${spacing(15)}) / 3)`,
      })};
    `}

    ${media.greaterThan('l')`
      width: ${styledMap('teaserVariant', {
        artwork: css`calc((100% - ${spacing(15)}) / 3)`,
        box: css`calc((100% - ${spacing(5)}) / 1.25)`,
        default: css`calc((100% - ${spacing(20)}) / 4)`,
      })};
    `}
  }

  

  ${({ teaserVariant }) => {
    if (teaserVariant) return ''

    return css`
      .beer-teaser__image {
        ${beerTeaserGridQueries}
        position: relative;
        width: 100%;

        & > div {
          display: flex;
          flex-flow: column;
          justify-content: flex-end;
          align-items: flex-end;
        }

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    `
  }}

  ${({ hasABV }) => {
    if (!hasABV) return ''

    return css`
      .list-item {

        width: ${styledMap('teaserVariant', {
          artwork: '100%',
          box: '100%',
          default: css`calc((100% - ${spacing(10)}) / 2)`,
        })};

      ${media.greaterThan('m')`
        width: ${styledMap('teaserVariant', {
          artwork: css`calc((100% - ${spacing(15)}) / 3)`,
          box: css`calc((100% - ${spacing(5)}) / 1.25)`,
          default: css`calc((100% - ${spacing(20)}) / 4)`,
        })};
      `}

        > div {
          display: flex;
          flex-flow: column;
          height: 100%;
        }
      }
    `
  }}
`

export default ({ noSidebar,hasABV, beers, icon, style, teaserVariant }) => {
  return (
    <SeriesBeerList
      teaserVariant={teaserVariant}
      noSidebar={noSidebar}
      hasABV={hasABV}
      style={style}
      className="beer-list"
    >
      {beers.map(({ uri, title, contentBeer, rowClasses }, index) => {
        if (teaserVariant === 'artwork') {
          return (
            <TeaserBeer
              key={title}
              path={uri}
              icon={icon}
              title={title}
              className="list-item"
              teaserVariant={teaserVariant}
              {...contentBeer}
            />
          )
        }

        return (
          <Link
            key={title}
            link={{ url: uri }}
            linkStyle="none"
            className="list-item"
          >
            <TeaserBeer
              path={uri}
              icon={icon}
              title={title}
              teaserVariant={teaserVariant}
              rowClasses={rowClasses}
              hasABV={hasABV}
              {...contentBeer}
            />
          </Link>
        )
      })}
    </SeriesBeerList>
  )
}
