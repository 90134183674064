export const useTallestInRow = (beers) => {
  const imageSlugValues = {
    'small-can': 0,
    'large-can': 1,
    'small-bottle': 2,
    'large-bottle': 3,
  }

  return beers.map((beer, index) => {
    beer.rowClasses = []
    for (let row = 4; row > 1; row--) {
      let rowTallest = ''

      for (let i = 0; i < row; i++) {
        const siblingBeer = beers[index - (index % row) + i]

        if (!siblingBeer) {
          continue
        }

        const { featureImageType: slug } = siblingBeer.contentBeer

        switch (true) {
          case !rowTallest:
            rowTallest = slug
            break
          case imageSlugValues[rowTallest] < imageSlugValues[slug]:
            rowTallest = slug
            break
        }
      }
      beer.rowClasses.push(`row-${row}--${rowTallest}`)
    }

    return beer
  })
}
