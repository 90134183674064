import React from 'react'
import { graphql } from 'gatsby'
import ComponentContent from '@blocks/ComponentContent'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import styledMap from 'styled-map'
import Text from '@components/Text'
import Modal from '@components/Modal'
import MediaItem from '@blocks/MediaItem'

import { LargeTypeSizes } from '@styles/Typography'
import { ComponentBase } from '@mixins/MixinsComponent'

export const MixerPackIntroText = styled.div`
  ${ComponentBase}
  width: 100%;

  ${media.lessThan('1199px')`
    margin-top: 0;
  `}

  .inner {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    
    ${media.greaterThan('m')`
      gap: 4rem;
      flex-flow: row nowrap;
    `}
  }

  .introContent {
    flex: 0 1 auto;
    margin: 0;
    order: -1;

    ${media.greaterThan('m')`
      order: unset;
    `}
  }

  p {
    ${LargeTypeSizes}
  }
`

export default ({ textContent, labelArtwork, className = '', size }) => {
  return (
    <MixerPackIntroText className={className} size={size}>
      <div className="inner">
        {labelArtwork && (
          <div className='modal-gallery-container'>
            <Modal
              className="modal-link"
              buttonLabel="View Artwork"
              isGallery
              size='vast'
              isSlider={false}
            >
              
              <MediaItem
                  media={{ image: labelArtwork, mediaType: 'image' }}
                  objectFit="contain"
                />
            </Modal>
          </div>
        )}
        <ComponentContent
          className="introContent"
          textContent={textContent}
          contentAlign="center"
          headingSize="shortLarge"
          size="narrow"
        />
      </div>
    </MixerPackIntroText>
  )
}
