/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import ComponentContainer from '@layout/ComponentContainer'
import MixerPackIntroText from '@components/MixerPack/MixerPackIntroText'
import SeriesBeerList from '@components/Series/SeriesBeerList'
import Slider from '@components/Slider'
import TeaserListRow from '@components/TeasersList/TeaserListRow'
import SeriesSlider from '@components/Series/SeriesSlider'
import Quote from '@components/Quote'
import { formatTemplateData } from '@utils/templates'
import { ComponentSpacer } from '@layout/FlexComponents'
import { useTallestInRow } from '@hooks/useTallestInRow'

export const BeerTemplate = ({
  data: {
    wpMixerPack: { contentMixerPack,uri, seo, ...mixerPackData },
    allWpMixerPack: { nodes: relatedMixerPack },
  },
}) => {
  seo.path = uri
  
  const { id, title, slug } = mixerPackData

  const {
    image:featureImage,
    featureImageType,
    featureImageBeerType,
    heading,
    subheading,
    introContent,
    relatedPosts,
    labelArtwork,
    beers: _beers,
    gallery,
    quote,
    ...mixerPack
  } = contentMixerPack
  const beers = useTallestInRow(_beers.map(beer => ({
      ...beer,
      contentBeer: {
        ...beer.contentBeer,
        featureImageType: featureImageBeerType
      }
    })
  ))

  const template = {
    activeSlug: 'beers',
    id,
    slug,
    // beerImageType: featureImageType || 'large-bottle',
    featureImage,
    featureImageType,
    title,
    designations: 'isMixerPackPage',
    heroType: 'mixPack',
    theme: 'dark',
    menuTheme: 'dark',
    seo,
    ...mixerPack,
  }

  const introTextProps = {
    textContent: {
      selectedFields: ['heading', 'subheading', 'content'],
      heading,
      subheading, 
      content: introContent,
    },
    labelArtwork,
    contentAlign: 'right',
    textAlign: 'left',
    size: 'wide',
  }


  const articleProps = {
    heading: `Learn More About ${title}`,
    headingSize: 'shortLarge',
    teasers: relatedPosts
      ? relatedPosts.map(
          ({ id, title: postTitle, uri, topCategory, eventDate }) => {
            return {
              id,
              uri,
              title: postTitle,
              date: eventDate,
              topCategory,
            }
          }
        )
      : null,
  }

  const relatedMixerPackProps = {
    heading: `More Hoyne Mix Packs`,
    beers: relatedMixerPack.map(mixerPack => ({...mixerPack, contentBeer: mixerPack?.contentMixerPack})),
  }

  return (
    <>
      <Container template={formatTemplateData(template)}>
        <MixerPackIntroText {...introTextProps} />
        <ComponentContainer size="wide" collapse>          
          <SeriesBeerList
            noSidebar
            beers={beers}
            hasABV
            // teaserVariant='default'
            style={{ justifyContent: 'center' }}
          />
        </ComponentContainer>

        {gallery && <Slider sliderImages={gallery}/>}
        <Quote {...quote} />

        {articleProps.teasers && (
          <ComponentContainer>
            <TeaserListRow {...articleProps} />
          </ComponentContainer>
        )}


        {relatedMixerPackProps.beers.length ? (
          <SeriesSlider {...relatedMixerPackProps} teaserVariant='box' number={2}/>
        ) : (
          <ComponentSpacer />
          )}
      </Container>
    </>
  )
}

BeerTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BeerTemplate

export const pageQuery = graphql`
query MixerPackByID($id: String!) {
  wpMixerPack(id: {eq: $id}) {
    uri
    seo {
      title
      twitterTitle
      twitterDescription
      twitterImage {
        description
        localFile {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      opengraphTitle
      opengraphImage {
        localFile {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      opengraphDescription
      metaRobotsNoindex
      metaRobotsNofollow
      metaDesc
    }
    id
    title
    slug
    contentMixerPack {
      heading
      subheading
      introContent
      featureImageType
      featureImageBeerType
      quote {
        content
        authorMeta
        author
      }
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      labelArtwork {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      beers {
        ... on WpBeer {
          id
          title
          uri
          contentBeer {
            abv
            featureImageType
            smallBottleImage {
              altText
              localFile {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 560, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            largeBottleImage {
              altText
              localFile {
                extension
                publicURL
                childImageSharp {
                  fluid(maxWidth: 560, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            smallCanImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            largeCanImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 560, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      relatedPosts {
        ... on WpPost {
          ...TeaserPostFields
        }
      }
      gallery {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
  allWpMixerPack(filter: {id: {ne: $id}}) {
    nodes {
      id
      title
      uri
      contentMixerPack {
        featureImageType
        image {
          altText
          localFile {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 560, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
}
`
